<template>
<div>
    <v-navigation-drawer class="d-print-none" v-model="$store.state.drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
        <!-- {{search_links}} -->
        <div v-for="(child, i) in items" :key="i">
            <router-link class="sidebar-link" :to="{ name: 'rolelink', params: { role:child.name }}">
                <v-list-item class="sidebar-link-item">
                    <span v-if="child.icon">
                        <v-icon size="32" class="instagram">{{ child.icon }}</v-icon>
                    </span>
                    <v-list-item-content>
                        <v-list-item-title class="title-role" style="">{{ child.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            <v-divider color="#90A4AE" style="margin:0px"></v-divider>
        </div>
    </v-navigation-drawer>
</div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            items: [],
             sorting : -1,
        };
    },
    methods: {
    },
    mounted() {
        axios
            .get("/home/dashboardsidebar")
            .then(res => {
                this.items = res.data.links;
                this.sortedArray
            })
            .catch(error => {
                window.console.log(error);
                this.$router.push("/");
            });
    },
// computed: {
//   sortedArray: function() {
//     function compare(a, b) {
//       if (a.name < b.name)
//         return -1;
//       if (a.name > b.name)
//         return 1;
//       return 0;
//     }

//     return this.items.sort(compare);
//   }
// }

};
</script>

<style scoped>
.sidebar-link {
    text-decoration: none;
}

.sidebar-link-item:hover {
    background-color: rgb(202, 206, 209) !important;
}

.title-role {
    margin-left: 35px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    line-height: 1.0;
    font-weight: 500;
}

.instagram {
    background: -webkit-radial-gradient(33% 100% circle, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    background: radial-gradient(circle at 33% 100%, #30CFD0 4%, #30CFD0 22%, #30CFD0 22%, #330867 85%, #330867);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>
